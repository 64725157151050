import { graphql } from "gatsby"
import React from "react"
import { BlocksRenderer } from "../components/BlocksRenderer"
import { CtaSection } from "../components/CtaSection"
import { HeadingBlock } from "../components/HeadingBlock"
import { Layout } from "../components/Layout"
import { ContentRow } from "../components/Row"
import { SEO } from "../components/SEO"
import "../lib/kirby.interface"

interface DetailPageProps {
  id: string
  title: string
  content: string
  ctaText: string
  ctaShow: boolean
  ctaTitle: string
  seoDescription: string
  seoTitle: string
}

const TipPage = ({ data }: Kirby.Data<{ detailPage: DetailPageProps }>) => {
  const content = data.kirby.detailPage

  const meta = [
    "algemene-voorwaarden",
    "cookieverklaring",
    "privacyverklaring",
  ].includes(content.id)
    ? [{ name: "robots", content: "noindex" }]
    : []

  return (
    <Layout>
      <SEO
        title={content.seoTitle || content.title}
        description={content.seoDescription}
        meta={meta}
      />

      <ContentRow first as="article">
        <HeadingBlock title={content.title} titleAs="h1"></HeadingBlock>
        <BlocksRenderer highlightFirst content={content.content} />
      </ContentRow>

      <CtaSection {...content} />
    </Layout>
  )
}

export default TipPage

export const query = graphql`
  query PageDetail($id: ID!) {
    kirby {
      detailPage(id: $id) {
        id
        title
        content
        ctaText
        ctaShow
        ctaTitle
        seoDescription
        seoTitle
      }
    }
  }
`
