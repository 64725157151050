import React from "react"
import { Row } from "./Row"
import { SectionTitle } from "./SectionTitle"
import { Section } from "./Section"
import { LinkButton } from "./Button"

interface CtaSectionProps {
  ctaShow: boolean
  ctaText: string
  ctaTitle: string
}

export const CtaSection = ({ ctaShow, ctaText, ctaTitle }: CtaSectionProps) => {
  if (!ctaShow) {
    return null
  }

  return (
    <Section color="#fff">
      <Row style={{ textAlign: "center" }}>
        <SectionTitle label={ctaTitle} content={ctaText} />
        <LinkButton to="/#pakketten">Bekijk de pakketten</LinkButton>
      </Row>
    </Section>
  )
}
