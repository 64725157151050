import styled from "@emotion/styled"
import React from "react"
import Theme from "../lib/Theme"
import { Heading } from "./Heading"
import { Text } from "./Text"

interface Props {
  label: string
  content?: string
  color?: string
  tag?: JSX.IntrinsicElements
}

const Wrap = styled.header<{ color?: string }>`
  position: relative;
  text-align: center;
  margin-bottom: 1rem;
  color: ${(p) => p.color || Theme.Colors.purple.s100};

  p {
    margin: 0 auto;
    max-width: 30rem;
  }
`

const Title = styled(Heading)`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  overflow: hidden;
  text-align: center;

  &:before,
  &:after {
    background-color: currentColor;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    top: -0.1em;
    vertical-align: middle;
    opacity: 0.2;
    width: 50%;
  }

  &:before {
    right: 0.5em;
    margin-left: -50%;
  }

  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
`

export const SectionTitle = ({ label, content, color, tag }: Props) => {
  return (
    <Wrap color={color}>
      <Title size={32} as={tag || "h2"}>
        {label}
      </Title>
      {content && <Text>{content}</Text>}
    </Wrap>
  )
}
